<template>
  <div class="container-fluid">
    <portal to="breadcrumb">
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </portal>
    <div class="container mb-5">
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <h4 class="my-1 flex-fill">Liste des demandeurs d'emploi | Nombre:{{ pagination.totalElements }}</h4>
          <form class="d-flex my-1">
            <input class="form-control" type="search" placeholder="Search" aria-label="Search">
            <button type="submit" class="btn btn-primary"><i class="bi bi-search"></i></button>
            <a href="#" class="btn btn-warning">Ajouter</a>
          </form>
        </div>
        <div class="bg-white p-3 border shadow">
          <div class="table-responsive">
            <table class="table table-striped align-middle">
              <thead class="border-bottom">
                <tr>
                  <th scope="col" v-for="(field,i) in tableFields" :key="i">{{ field.label }}</th>
                </tr>
              </thead>
              <tbody class="text-muted">
                <tr v-for="(data, index) in tableData" :key="index">
                  <template v-for="(field,i) in tableFields" >
                    <template v-if="field.key !== 'actions' && field !== 'actions' ">
                      <td v-if="field.key=='commune'" :key="i">{{data[field.key].nom}}</td>
                      <td v-else :key="i">{{data[field.key]}}</td>
                    </template>
                    <td v-else :key="i">
                      <div class="d-flex">
                        <a class="btn btn-success rounded-0 btn-sm me-1"><i class="bi bi-pencil-square"></i></a>
                        <a class="btn btn-info rounded-0 btn-sm me-1"><i class="bi bi-info-square"></i></a>
                        <a class="btn btn-danger rounded-0 btn-sm me-1"><i class="bi bi-trash"></i></a>
                      </div>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>Affichage de l’élément {{pagination.numberOfElements}} de {{pagination.totalElements}} éléments</p>
            <!-- <nav class="">
              <ul class="pagination justify-content-end">
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">«</span>
                  </a>
                </li>
                <li class="page-item active"><a class="page-link" href="#">1</a></li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">»</span>
                  </a>
                </li>
              </ul>
            </nav> -->
            <div class="d-flex justify-content-between align-items-center mt-4" v-if="demandeurs && demandeurs.length">
              <nav class=''>
                <ul class="pagination justify-content-end">
                  <li class="page-item">
                    <a class="page-link" :class="{ 'disabled': pagination.pageNumber<1 }" @click.prevent="fetchDemandeurs({ pageNumber: pagination.pageNumber - 1 })" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <template v-if="pagination.totalPages<10">
                    <li class="page-item" :class="{ 'active': page-1 == pagination.pageNumber }" v-for="(page, index) in pagination.totalPages" :key="index" @click.prevent="fetchDemandeurs({ pageNumber: page-1 })"><a class="page-link" href="#">{{page}}</a></li>
                  </template>
                  <template v-else>
                    <li class="page-item" :class="{ 'active': page-1 == pagination.pageNumber }" v-for="(page, index) in 3" :key="index" @click.prevent="fetchDemandeurs({ pageNumber: page-1 })"><a class="page-link" href="#">{{page}}</a></li>
                    <li class="page-item" :key="index" @click.prevent="fetchDemandeurs({ pageNumber: page-1 })"><a class="page-link" href="#">...</a></li>
                    <li class="page-item" :class="{ 'active': page-1 == pagination.pageNumber }" v-for="(page, index) in 3" :key="index" @click.prevent="fetchDemandeurs({ pageNumber: page-1 })"><a class="page-link" href="#">{{pagination.totalPages-page}}</a></li>
                  </template>
                  
                  <li class="page-item">
                    <a class="page-link" v-if="pagination.pageNumber < pagination.totalPages - 1" @click.prevent="fetchDemandeurs({ pageNumber: pagination.pageNumber + 1 })" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    <div class="px-4 pt-2 pb-5">
      <div class="bg p-3">
        <!-- <b-card class="rounded-">
          <div class="d-flex justify-content-between">
            <h4 class="text-dark">Liste des demandeurs <b-badge pill size="sm" variant="info">{{ pagination.totalElements }} demandeurs</b-badge></h4>
            <div class="d-flex justify-content-between mr-md">
              <button @click.prevent="$router.push({ name: 'espace.admin.demandeurs.edit', params: { demandeurId: undefined } })" class="btn btn-success btn- mr-2">Ajouter <i class="fa fa-plus-circle"></i></button>
              <button @click.prevent="$router.push({ name: 'espace.admin.demandeurs.edit', params: { demandeurId: undefined } })" class="btn btn-danger mr-2">Supprimer <i class="fa fa-trash-alt"></i></button>
              <button @click.prevent="$router.push({ name: 'espace.admin.demandeurs.edit', params: { demandeurId: undefined } })" class="btn btn-outline-info mr-2">Exporter <i class="fa fa-file-excel"></i></button>
              <button @click.prevent="$router.push({ name: 'espace.admin.demandeurs.edit', params: { demandeurId: undefined } })" class="btn btn-info">Imprimer <i class="fa fa-print"></i></button>
            </div>
          </div>
        </b-card> -->
        <!-- <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
					<h4 class="my-1 flex-fill">Réseau: Consortium Alafia | Année: 2019 | Nombre SFD: 51</h4>
					<form class="d-flex my-1">
            <input class="form-control" type="search" placeholder="Search" aria-label="Search">
            <button type="submit" class="btn btn-primary"><i class="bi bi-search"></i></button>
            <a href="#" class="btn btn-warning">Ajouter</a>
          </form>
				</div> -->
        <b-card class="border-0 rounded-0 bg-light" border-variant="transparent">
          <div class="d-flex justify-content-between">
            <div class="col-md-4">
              <label for="">Rechercher</label>
              <input type="text" v-model="mxSearch" class="form-control" name="" placeholder="Rechercher">
            </div>
            <form class="form">
              <div class="row">
                <div class="col" v-for="(opt, i ) in Object.keys(mxFilter)" :key="i">
                  <label for="">{{ mxFilter[opt].label  }}</label>
                  <b-form-select v-model="mxFilter[opt].value" placeholder="HO" :options="sexeOptions" />
                </div>
              </div>
              <div class="form-group mr-md">
                <!-- <button @click.prevent="$router.push({ name: 'espace.admin.demandeurs.edit', params: { demandeurId: undefined } })" class="btn btn-success">Ajouter un demandeur</button> -->
                <demandeur-edit v-if="false" ref="demandeurEditor" @saved="updateOrCreateDemandeur" btnText="Créer un demandeur"  :item="activeDemandeur" title="Ajouter un demandeur"></demandeur-edit>
              </div>
            </form>
          </div>
          <!-- <b-dd-divider/> -->
        </b-card>
        <b-card no-body class="rounded-0 shadow-0 elevation-0 border- border-top">
          <div class="table-responsive px-2">
            <b-table show-empty empty-text="Aucune donnée à afficher" :fields="tableFields" borderless :th-class="['border-bottom', 'border-top']" striped hover :items="tableData">
              <template #head()="data">
                <span>{{ data.label }} </span>
            </template>
            <!-- A virtual column -->
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(actions)="data">
              <div class="d-flex">
                <!-- <a href="#" @click.prevent="editDemandeur(data.item)" title="Modifier" class="text-secondary mr-2"><i class="fa fa-edit"></i></a>
                <a href="#" @click.prevent="destroyDemandeur(data.item)" title="Supprimer" class="text-danger mr-2"><i class="fa fa-trash-alt"></i></a>
                <a href="#" title="Détail" class="text-info mr-2"><i class="fa fa-eye"></i></a> -->
              <b-dropdown id="dropdown-1" text="Affecter" split variant="outline-success" split-variant="success" class="m-md-2 text-decoration-none border-none shadow-0">
                <!-- <template #button-content>
                  <a href="#" title="Affecter" class="btn btn-outline-success btn-outline text- mr-2">
                    Affecter
                    <i class="fa fa-ellipsis-v"></i>
                  </a>
                </template> -->
                <b-dropdown-item @click.prevent="editDemandeur(data.item)"><i class="fa fa-edit"></i> Modifier</b-dropdown-item>
                <b-dropdown-item class="text-secondary mr-2"><i class="fa fa-eye"></i> Voir</b-dropdown-item>
                <b-dropdown-item @click.prevent="destroyDemandeur(data.item)" ><i class="fa fa-trash-alt"></i> Supprimer</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item>Affecter</b-dropdown-item>
              </b-dropdown>
              </div>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template #cell()="data">
              <span>{{ data.value }}</span>
            </template>
            </b-table>
          </div>
        </b-card>
        <div class="d-flex justify-content-between align-items-center mt-4" v-if="demandeurs && demandeurs.length">
          <p>{{ pagination.numberOfElements }} éléments sur {{ pagination.totalElements }}</p>
          <nav class=''>
            <ul class="pagination justify-content-end">
              <li class="page-item">
                <a class="page-link" v-if="pagination.pageNumber>0" @click.prevent="fetchDemandeurs({ pageNumber: pagination.pageNumber - 1 })" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li class="page-item" :class="{ 'active': page-1 == pagination.pageNumber }" v-for="(page, index) in pagination.totalPages" :key="index" @click.prevent="fetchDemandeurs({ pageNumber: page-1 })"><a class="page-link" href="#">{{page}}</a></li>
              <li class="page-item">
                <a class="page-link" v-if="pagination.pageNumber < pagination.totalPages - 1" @click.prevent="fetchDemandeurs({ pageNumber: pagination.pageNumber + 1 })" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import DemandeurEdit from '@/components/espace/demandeur/DemandeurEdit'
import { paginatorMixin } from '@/mixins/paginator-mixin'

export default {
  components: {
    DemandeurEdit
  },
  mixins: [paginatorMixin],
  data () {
    return {
      dtFilteredItems:[],
      dtFullItems:[],
      search: '',
      filterOptions: [
        {
          column: 'departement',
          value: '',
          label: 'Département',
          items: []
        },
        {
          column: 'commune',
          value: '', 
          label: 'Commune',
          items: []
        },
        // {
        //   column: 'village',
        //   query: '',
        //   label: 'Ville/Village',
        //   items: []
        // },
        // {
        //   column: 'language',
        //   query: '',
        //   label: 'Langue',
        //   items: []
        // },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        // _queryOptions: {
        //   departement: 'departement',
        //   skills: 'skills__in',
        //   languages: 'languages__in',
        //   availabilities: 'availability__in',
        //   level: 'level',
        //   price_min: 'budget__gte',
        //   price_max: 'budget__lte'
        // },
        _queryOptions: [
          {
            column: 'departement',
            value: null,
            label: 'Département'
          },
          {
            column: 'commune',
            value: null, 
            label: 'Commune'
          },
        ],
        ordering: ''
        // _orderingOptions: {
        // }
      },
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: 'Admin',
          href: '#'
        },
        {
          text: 'Paramètres',
          href: '#'
        },
        {
          text: 'Demandeurs',
          active: true
        }
      ]
    }
  },
  created () {
    this.mxInitializePaginator({
      queryModel: 'demandeur',
      search: this.search,
      fetcherMethod: 'demandeur/fetchDemandeurs',
      dataGetter: 'demandeur/demandeurs',
      paginationGetter: 'demandeur/pagination',
      pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 3
      },
      filterOptions: this.filterOptions,
      searchFields: [ 'search' ]
    })
  },
  computed: {
    ...mapGetters({
      demandeurs: 'demandeur/demandeurs',
      pagination: 'demandeur/pagination'
    }),
    tableFields () {
      return [
        'index',
        { key: 'matricule', label: 'Matricule' },
        { key: 'nom', label: 'Nom' },
        { key: 'prenom', label: 'Prénom' },
        { key: 'sexe', label: 'Sexe' },
        { key: 'sexe', label: 'Sexe' },
        { key: 'phone', label: 'Téléphone' },
        { key: 'commune', label: 'Commune' },
        'actions',
      ]
    },
    tableData () {
      return this.mxFullItems.map(item => ({ ...item }))
    },
    sexeOptions () {
      return ['M', 'F'].map(item => ({ value: item, text: item }) )
    },
  },
  methods: {
    ...mapActions({
      fetchDemandeurs: 'demandeur/fetchDemandeurs',
      createOrUpdateDemandeur: 'demandeur/createOrUpdateDemandeur',
      deleteDemandeur: 'demandeur/deleteDemandeur',
    }),
    editDemandeur (demandeur) {
      console.log(demandeur)
      this.activeDemandeur = { ...demandeur }
      this.$refs['demandeurEditor'].show()
    },
    async destroyDemandeur (demandeur) {
      console.log(demandeur)
      await this.$dialog.confirm({
        size: 'md',
        centered: true,
        variant: 'success',

        text: 'Voulez-vous vraiment supprimer cet élément?',
        actions: {
          false: {
            text: 'Non',
            variant: 'outline-success'
          },
          true: {
            text: 'Oui',
            variant: 'success',
            handle: async () => {
              this.deleteDemandeur(demandeur)

            },
          },
        },
      })
      // const ok = await this.$dialog.confirm({ text: 'Do you really want to exit?'})
      // if (ok) {
      // }
    },
    updateOrCreateDemandeur (demandeur) {
      console.log(demandeur)
      this.createOrUpdateDemandeur(demandeur).then(data => {
        console.log(data)
        this.$toast.success("Opération réussie!", {
            position: 'bottom-right',
            duration: 5000
          })
      })
    }
  }
}
</script>

<style>

</style>