<template>
  <div>
    <slot name="action"> <b-button variant="success" v-b-modal.modal-footer-sm>Créer une agent</b-button> </slot>
    <b-modal id="modal-footer-sm" ref="modal" @ok="ok" @cancel="cancel" :title="title" modal-ok="Valider" modal-cancel="Annuler">
      <form class="">
        <div class="row">
          <div class="col-sm-12 form-group">
            <label> Libellé du fonction </label>
            <b-form-input type="text" v-model="editableItem.nom" name="nom" placeholder="Libellé du fonction" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 form-group">
            <label> Département </label>
            <b-form-select placeholder="Entrer le nom de la structure" v-model="editableItem.departement" :options="departementOptions"></b-form-select>
            <!-- <input type="text" v-model="editableItem.libelle" class="form-control style_form_control" name="libelle" placeholder="Libellé du sous-acte"> -->
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 form-group">
            <label> Commune </label>
            <b-form-select placeholder="Entrer le nom de la structure" v-model="editableItem.commune" :options="communeOptions"></b-form-select>
            <!-- <input type="text" v-model="editableItem.libelle" class="form-control style_form_control" name="libelle" placeholder="Libellé du sous-acte"> -->
          </div>
        </div>
        
      </form>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm"  class="style_btn btn rounded-0" @click="cancel()">
          Annuler
        </b-button>
        <b-button size="sm"  class="style_btn btn rounded-0 btn  btn-success" @click="ok()">
          Valider
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: {
      title: {
        type:  String,
        required: true
      },
      item: {
        type:  Object,
        default: () => ({ })
      }
    },
    data () {
      return {
        editableItem: {}
      }
    },
    created () {
      this.fetchDepartements()
    },
    watch: {
      item () {
        this.editableItem = { ...this.item }
      },
      editableItem (val, oldVal) {
        console.log(val, oldVal)
        if (val.departement != undefined  && val.departement.id != undefined) {
          this.fetchDepartementCommunes({
            departementId: val.departement.id
          })
        }
      }
    },
    computed: {
      ...mapGetters({
        departements: 'departement/departements',
        communes: 'commune/communes',
        agents: 'agent/agents'
      }),
      options () {
        return this.structures.map(item => ({ value: item, text: item.nom }) )
      },
      departementOptions () {
        return this.departements.map(item => ({ value: item, text: item.nom }) )
      },
      communeOptions () {
        return this.communes.map(item => ({ value: item, text: item.nom }) )
      },
    },
    methods: {
       ...mapActions({
        fetchDepartementCommunes: 'commune/fetchDepartementCommunes',
        fetchDepartements: 'departement/fetchDepartements',
        fetchCommunes: 'commune/fetchCommunes',
      }),
      show () {
        this.$refs['modal'].show()
      },
      hide () {
        this.$refs['modal'].hide()
      },
      ok () {
        this.$emit('saved', this.editableItem)
        this.editableItem = {}
      },
      cancel () {
        this.$emit('canceled')
      }
    }
    
  }
</script>